function smoothScroll(
  destination,
  duration = 200,
  easing = "linear",
  callback
) {
  const start = window.pageYOffset;
  const startTime =
    "now" in window.performance ? performance.now() : new Date().getTime();

  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.getElementsByTagName("body")[0].clientHeight;
  const destinationOffset =
    typeof destination === "number" ? destination : destination.offsetTop;
  const destinationOffsetToScroll = Math.round(
    documentHeight - destinationOffset < windowHeight
      ? documentHeight - windowHeight
      : destinationOffset
  );
  let frameRequest = true;

  if ("requestAnimationFrame" in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    if (callback) {
      callback();
    }
    return;
  }

  function scroll() {
    const now =
      "now" in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, (now - startTime) / duration);
    const timeFunction = easing(time);
    window.scroll(
      0,
      Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start)
    );

    if (window.pageYOffset === destinationOffsetToScroll) {
      window.removeEventListener("wheel", stopScroll);
      window.removeEventListener("keyup", stopScroll);
      window.removeEventListener("touchmove", stopScroll);
      if (callback) {
        callback();
      }
      return;
    }

    if (!frameRequest) {
      window.removeEventListener("wheel", stopScroll);
      window.removeEventListener("touchmove", stopScroll);
      return;
    }

    requestAnimationFrame(scroll);
  }

  function stopScroll() {
    frameRequest = false;
  }

  scroll();

  window.addEventListener("wheel", stopScroll);
  window.addEventListener("touchmove", stopScroll);
}

export default smoothScroll;
