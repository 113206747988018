import "./index.scss";
import ContactFormHandler from "./assets/contact/contactFormHandler";
import ScrollHandler from "./assets/scroll/scrollHandler";
import PagesHandler from "./assets/pages/pagesHandler";
import "core-js/stable";
import "regenerator-runtime/runtime";
import RellaxHandler from "./assets/rellax/RellaxHandler";

(function main() {
  ScrollHandler.init(); // Initialisation du gestionnaire de scroll
  ContactFormHandler.init(); // Initialisation du gestionnaire de formulaire
  PagesHandler.init(); // Initialisation du gestionnaire de pages internes
  RellaxHandler.init(); // Initialisation de la parallaxe
})();
