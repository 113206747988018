const fileLoad = function(url) {
  return fetch(url)
    .then(response => {
      if (!response.ok)
        throw new Error(`Impossible de charger le fichier ${url}`);
      return response.text();
    })
    .catch(error => {
      throw new Error(error.message || `Échec d'ouverture du fichier ${url}`);
    });
};

const breakPoint = {
  xs: 396,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  hxs: 400,
  hsm: 514,
  hmd: 663,
  hlg: 802
};

export { fileLoad, breakPoint };
