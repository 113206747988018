import "../../pages/mentionslegales.md";
import "../../pages/politiqueconfidentialite.md";
import MarkdownIt from "markdown-it";
import "./pages.scss";
import "../_global/_global.scss";
import { fileLoad } from "../_global/utils";
import "../../img/loader.svg";
import "../../pages/consultation.php";

const PagesHandler = (function() {
  // Module qui identifie les liens vers des pages internes (rédigées en markdown)
  // et intercepte leur activation pour en afficher le contenu dans une modale en ajax
  // plutôt que vers un nouvel onglet généré en php (qui est le comportement par défaut
  // en cas de js désactivé ou non exécutable sur navigateur obsolète par exemple)

  let self = {};

  const pagesLocation = "./pages/";

  const pagesLinks = Array.from(
    document.querySelectorAll(`a[href^='${pagesLocation}'`)
  );

  let imgLoader = null; // Chargement de l'image du loader en asynchrone
  let fileLoader = null; // Variable qui porte la promesse lors du chargement d'une page

  const bindEvents = function() {
    pagesLinks.forEach(pageLink => {
      pageLink.addEventListener("click", clickHandler);
    });
  };

  const pageFinder = function(url) {
    return pagesLocation + url.slice(url.lastIndexOf("=") + 1) + ".md";
  };

  const createPopin = function() {
    const overlay = document.createElement("div");
    const popin = document.createElement("div");
    let overlayElement = null,
      popinElement = null,
      closeButtonElement = null;

    if (document.getElementById("popin")) closePopin();

    overlay.id = "overlay";
    overlay.classList.add("Popin__overlay");
    popin.id = "popin";
    popin.innerHTML = `<div id="closeButton" role="button" tabindex=0 class="Popin__closeButton"><div></div><div></div></div><div id="popinContent" class="Popin__content"></div>`;
    popin.classList.add("Popin__box");

    overlayElement = document.body.appendChild(overlay);
    popinElement = document.body.appendChild(popin);

    setTimeout(() => {
      if (
        imgLoader &&
        !popinElement.querySelector("#popinContent").hasChildNodes()
      ) {
        const popinContentElement = popinElement.querySelector("#popinContent");
        popinContentElement.innerHTML = imgLoader;
        popinContentElement.classList.add("Popin__content--animated");
      }
    }, 200);

    document.body.style.overflow = "hidden";

    overlayElement.addEventListener("click", closePopin);

    closeButtonElement = document.getElementById("closeButton");
    closeButtonElement.addEventListener("click", closePopin);
    closeButtonElement.addEventListener("keyup", closePopin);

    return popinElement;
  };

  const displayPopin = function(page) {
    const md = new MarkdownIt();
    const popin = createPopin();

    return fileLoad(page)
      .then(response => {
        if (popin) {
          const popinContent = popin.querySelector("#popinContent");
          if (popinContent.classList.contains("Popin__content--animated"))
            popinContent.classList.remove("Popin__content--animated");
          popinContent.innerHTML = md.render(response);
        } else {
          throw new Error(`Pas de popin pour afficher le contenu`);
        }
      })
      .catch(error => {
        closePopin();
        throw new Error(error.message);
      });
  };

  const closePopin = function(e) {
    if (e.type === "keyup" && !(e.keyCode === 32 || e.keyCode === 13)) return;
    const popin = document.getElementById("popin");
    const overlay = document.getElementById("overlay");

    if (document.body.hasAttribute("style"))
      document.body.removeAttribute("style");
    if (popin) {
      popin.classList.add("Popin__box--disappearing");
      setTimeout(() => {
        popin.remove();
      }, 180);
    }
    if (overlay) {
      overlay.classList.add("Popin__overlay--disappearing");
      setTimeout(() => {
        overlay.remove();
      }, 180);
    }
  };

  const clickHandler = function(event) {
    if (window.innerWidth <= 576) return;
    const page = pageFinder(event.target.href);
    event.preventDefault();
    fileLoader = displayPopin(page).catch(error => {
      console.warn(
        `Erreur rencontrée lors de la tentative d'affichage d'un lien dans la page.\r
        Lien : ${event.target.href}\r
        Erreur : ${error.message}.\r
        Solution : Tentative d'ouverture du lien dans un nouvel onglet.`
      );
      window.open(event.target.href, "_blank");
    });
  };

  self.init = function() {
    bindEvents();
    fileLoad("./img/loader.svg")
      .then(response => {
        imgLoader = response;
      })
      .catch(error => {
        console.warn(
          `Le pictogramme "loader" n'a pas pu être chargé (${error.message})`
        );
      });
  };

  return self;
})();

export default PagesHandler;
