import "../../img/loader.svg";
import "../../img/failure.svg";
import "../../img/success.svg";
import "./contact.php";
import { fileLoad } from "../_global/utils";
import "./contactForm.scss";

const ContactFormHandler = (function() {
  // Module qui contrôle les champs de formulaire, envoie la requête au serveur et gère la réponse
  // Pour ajouter des placeholders dans le html, utiliser data-placeholder
  // Pour indiquer qu'un contrôle par expression régulière est nécessaire, l'indiquer dans le html via data-format

  //const contactAPI = "http://localhost:8888/"; // For development environement only
  const contactAPI = "./api/contact.php";
  const email = "laurent.guy@lgc-energies.fr";

  let self = {};
  let imgLoader, imgSuccess, imgFailure; //Les SVG du feedback seront préchargés de manière asynchrone après le chargement de la page

  const fields = document.querySelectorAll(
    ".ContactForm__field input, textarea"
  );
  const submit = document.getElementById("ContactForm_submit");
  const messageBox = document.getElementById("MessageBox");

  const regex = [
    // Mapping des formats à contrôler d'une expression régulière défini dans l'attribut data-format du html
    {
      format: "name",
      rule: `^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$`
    },
    {
      format: "email",
      rule:
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
    }
  ];

  const bindEvents = function(field) {
    field.addEventListener("focus", focusHandler);
    field.addEventListener("blur", blurHandler);
    field.addEventListener("keyup", keyupHandler);
  };

  const focusHandler = function(event) {
    const field = event.target;
    const parent = field.parentNode;

    removeMessage();

    if (parent.classList.contains("ContactForm__field--valid")) {
      parent.classList.remove("ContactForm__field--valid");
    }

    if (
      parent.classList.contains("ContactForm__field--error") &&
      !field.value
    ) {
      removeFeedback(field);
    }

    parent.classList.add("ContactForm__field--focused");
    parent.classList.add("ContactForm__field--active");

    if (field.getAttribute("data-placeholder")) {
      field.setAttribute("placeholder", field.getAttribute("data-placeholder"));
    }
  };

  const blurHandler = function(event) {
    const field = event.target;
    const parent = field.parentNode;

    parent.classList.remove("ContactForm__field--focused");

    if (!field.value) {
      parent.classList.remove("ContactForm__field--active");
      field.removeAttribute("placeholder");
    }

    controlField(field);

    if (
      field.value &&
      !parent.classList.contains("ContactForm__field--error")
    ) {
      parent.classList.add("ContactForm__field--valid");
    }
  };

  const keyupHandler = function(event) {
    const field = event.target;
    const parent = field.parentNode;

    if (parent.classList.contains("ContactForm__field--error")) {
      controlField(field);
    }
  };

  const hasRule = function(format) {
    for (let reg in regex) {
      if (format === regex[reg].format) return regex[reg].rule;
    }
    return;
  };

  const fieldValidator = function(field) {
    const label = field.parentNode.querySelector("label").innerHTML;
    const rule = hasRule(field.getAttribute("data-format"));

    if (field.required && !field.value) {
      return {
        valid: false,
        message: `Veuillez saisir ${label.toLowerCase()}`
      };
    }
    if (rule && !RegExp(rule).test(field.value)) {
      return {
        valid: false,
        message: `${label} a un format incorrect`
      };
    }
    return { valid: true };
  };

  const controlField = function(field) {
    const ctrl = fieldValidator(field);
    const parent = field.parentNode;

    if (!ctrl.valid) {
      addFeedback(field, ctrl.message);
    } else {
      if (parent.classList.contains("ContactForm__field--error")) {
        removeFeedback(field);
      }
    }
  };

  const addFeedback = function(field, message) {
    const div = document.createElement("div");
    const parent = field.parentNode;

    if (parent.classList.contains("ContactForm__field--error")) {
      removeFeedback(field);
    }

    div.innerHTML = message;
    parent.classList.add("ContactForm__field--error");
    parent.appendChild(div);
  };

  const removeFeedback = function(field) {
    const parent = field.parentNode;
    if (!parent.classList.contains("ContactForm__field--error")) return;
    parent.querySelector("div").remove();
    parent.classList.remove("ContactForm__field--error");
  };

  const submitHandler = function(event) {
    if (
      event.type === "keyup" &&
      !(event.keyCode === 32 || event.keyCode === 13)
    )
      return;

    let isFormValid = true;
    event.preventDefault();
    fields.forEach(field => {
      controlField(field);
      if (field.parentNode.classList.contains("ContactForm__field--error")) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      fields.forEach(field => {
        field.disabled = true;
      });
      submit.removeEventListener("click", submitHandler);
      submit.removeEventListener("keyup", submitHandler);
      displayMessage("pending");
      messageHandler();
    }
  };

  const displayMessage = function(status, message = "") {
    const messageInside = document.createElement("div");
    const msg = document.createElement("div");

    switch (status) {
      case "pending":
        if (imgLoader) messageInside.innerHTML = imgLoader;
        messageInside.innerHTML += `<span>Envoi en cours</span>`;
        if (
          !messageBox.classList.contains("ContactForm__messageBox--pending")
        ) {
          messageBox.classList.add("ContactForm__messageBox--pending");
        }
        if (messageBox.classList.contains("ContactForm__messageBox--failure")) {
          messageBox.classList.remove("ContactForm__messageBox--failure");
        }
        break;
      case "success":
        if (imgSuccess) messageInside.innerHTML = imgSuccess;
        messageInside.innerHTML += `<span>Message envoyé !</span>`;
        if (messageBox.classList.contains("ContactForm__messageBox--pending")) {
          messageBox.classList.remove("ContactForm__messageBox--pending");
        }
        if (messageBox.classList.contains("ContactForm__messageBox--failure")) {
          messageBox.classList.remove("ContactForm__messageBox--failure");
        }
        break;
      case "failure":
        if (imgFailure) messageInside.innerHTML = imgFailure;
        messageInside.innerHTML += `<span>Échec d'envoi du message</span>`;
        if (messageBox.classList.contains("ContactForm__messageBox--pending")) {
          messageBox.classList.remove("ContactForm__messageBox--pending");
        }
        if (
          !messageBox.classList.contains("ContactForm__messageBox--failure")
        ) {
          messageBox.classList.add("ContactForm__messageBox--failure");
        }
        break;
    }

    msg.innerText = message;
    msg.classList.add("ContactForm__messageBoxMsg");
    messageInside.classList.add("ContactForm__messageBoxInside");

    if (messageBox.querySelector(".ContactForm__messageBoxInside")) {
      messageBox.innerHTML = "";
    }

    messageBox.appendChild(messageInside);
    messageBox.appendChild(msg);

    if (!messageBox.classList.contains(".ContactForm__visible")) {
      messageBox.classList.add("ContactForm__messageBox--visible");
    }
  };

  const removeMessage = function() {
    if (messageBox.classList.contains("ContactForm__messageBox--visible")) {
      messageBox.classList.remove("ContactForm__messageBox--visible");
    }
    if (messageBox.classList.contains("ContactForm__messageBox--pending")) {
      messageBox.classList.remove("ContactForm__messageBox--pending");
    }
    if (messageBox.classList.contains("ContactForm__messageBox--failure")) {
      messageBox.classList.remove("ContactForm__messageBox--failure");
    }
    messageBox.innerHTML = "";
  };

  const waitMinimumDelay = function(delay) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, delay);
    });
  };

  const sendMessage = function() {
    return fetch(contactAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: document.getElementById("name").value,
        email: document.getElementById("email").value,
        message: document.getElementById("message").value
      })
    });
  };

  const activateForm = function() {
    fields.forEach(field => {
      field.disabled = false;
    });
    submit.addEventListener("click", submitHandler);
    submit.addEventListener("keyup", submitHandler);
  };

  const messageHandler = function() {
    Promise.all([waitMinimumDelay(1200), sendMessage()])
      .then(response => {
        if (response[1].ok) {
          response[1].json().then(json => {
            if (json.success) {
              displayMessage("success", json.message);
            } else {
              displayMessage("failure", json.message);
              activateForm();
            }
          });
        } else {
          throw new Error(`Code ${response[1].status}`);
        }
      })
      .catch(error => {
        console.error(`Erreur lors de l'envoi du message : ${error.message}`);
        displayMessage(
          `failure`,
          `Une erreur technique est survenue. Vous pouvez réessayer ou me contacter directement à ${email}`
        );
        activateForm();
      });
  };

  self.init = function() {
    fields.forEach(field => {
      if (field.value) {
        field.parentNode.classList.add("ContactForm__field--active");
      }
      bindEvents(field);
    });
    activateForm();

    //Préchargement des images du feedback en cas de connexion lente
    fileLoad("./img/loader.svg")
      .then(response => {
        imgLoader = response;
      })
      .catch(error => {
        console.warn(
          `Le pictogramme "loader" n'a pas pu être chargé (${error.message})`
        );
      });

    fileLoad("./img/success.svg")
      .then(response => {
        imgSuccess = response;
      })
      .catch(error => {
        console.warn(
          `Le pictogramme "success" n'a pas pu être chargé (${error.message})`
        );
      });

    fileLoad("./img/failure.svg")
      .then(response => {
        imgFailure = response;
      })
      .catch(error => {
        console.warn(
          `Le pictogramme "failure" n'a pas pu être chargé (${error.message})`
        );
      });
  };

  return self;
})();

export default ContactFormHandler;
