import Rellax from "rellax";
import { breakPoint } from "./../_global/utils";

const RellaxHandler = (function() {
  let self = {};
  let windowHeight = null;
  let rellax = null;

  const updateParallaxe = () => {
    windowHeight = window.innerHeight;
    if (windowHeight < breakPoint.sm) {
      if (rellax) {
        rellax.destroy();
        rellax = null;
      }
    } else {
      if (!rellax) {
        rellax = new Rellax(".rellax");
      }
    }
  };

  self.init = () => {
    updateParallaxe();
    window.addEventListener("resize", updateParallaxe);
  };

  return self;
})();

export default RellaxHandler;
