import smoothScroll from "./smoothScroll.js";
import { easeOutQuint as easing } from "./easing.js";

const ScrollHandler = (function() {
  // Module qui gère et configure les liens internes pour leur appliquer le smooth scroll
  let self = {};

  const internalLinks = Array.from(document.querySelectorAll("a[href^='#'")),
    destIds = internalLinks.map(link => link.getAttribute("href")),
    dests = destIds.map(destId => document.getElementById(destId.substring(1))),
    nbLinks = internalLinks.length;

  const bindEvents = function() {
    for (let i = 0; i < nbLinks; i++) {
      internalLinks[i].addEventListener("click", eventHandler);
      internalLinks[i].addEventListener("keyup", eventHandler);
    }
  };

  const eventHandler = function(e) {
    if (e.type === "keyup" && !(e.keyCode === 32 || e.keyCode === 13)) return;
    const i = internalLinks.indexOf(this);
    e.preventDefault();
    smoothScroll(dests[i], 1500, easing);
  };

  self.init = function() {
    bindEvents();
  };

  return self;
})();

export default ScrollHandler;
